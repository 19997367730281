import React, { useCallback, useMemo } from "react";

import clsx from "clsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from "@heroicons/react/20/solid";

import { Button } from "../base/Button";
import {
  TemplateEditDataPoint,
  useTemplateEditContext,
} from "../../contexts/TemplateEditContext";

interface DataPointListItemProps {
  dataPoint: TemplateEditDataPoint;
  selected: boolean;
  onClick: (dataPoint: TemplateEditDataPoint) => void;
  viewConfig?: boolean;
}

const DataPointListItem = React.memo(function DataPointListItem({
  dataPoint,
  selected,
  onClick,
  viewConfig,
}: DataPointListItemProps) {
  const handleClick = useCallback(
    () => onClick(dataPoint),
    [dataPoint, onClick]
  );

  return (
    <div
      onClick={handleClick}
      className={clsx(
        "flex flex-row justify-between space-x-2 px-2 py-1 rounded hover:cursor-pointer",
        selected && "bg-primary"
      )}
    >
      <p
        className={clsx(
          "text-sm",
          selected && "text-white",
          dataPoint.hide && "text-gray-500 dark:text-gray-400",
          dataPoint.hide && selected && "!text-gray-200"
        )}
      >
        {dataPoint.dataPoint.name}
        {dataPoint.dataPoint.unit && (
          <span className="ml-1 text-xs text-gray-600 dark:text-gray-400">
            ({dataPoint.dataPoint.unit})
          </span>
        )}
      </p>
      {!viewConfig && (
        <div
          className={clsx(
            "flex items-center space-x-2",
            selected && "text-white",
            dataPoint.hide && "text-gray-500 dark:text-gray-400",
            dataPoint.hide && selected && "!text-gray-200"
          )}
        >
          {dataPoint.dataPoint.hasActions ? (
            dataPoint.control === true ? (
              <FontAwesomeIcon icon={["fas", "toggle-on"]} />
            ) : (
              <FontAwesomeIcon icon={["fas", "toggle-off"]} />
            )
          ) : (
            <></>
          )}
          {dataPoint.dataPoint.customerEnable === true ? (
            <FontAwesomeIcon icon={["fas", "thumbtack"]} />
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
});

interface TemplateViewConfigDatapointSelectProps {
  viewConfig?: boolean;
}

export function TemplateViewConfigDatapointSelect({
  viewConfig = false,
}: TemplateViewConfigDatapointSelectProps) {
  const {
    state: template,
    dispatch,
    selectedDataPoint,
    setSelectedDataPoint,
  } = useTemplateEditContext();

  const dispatchAction = useCallback(dispatch, [dispatch]);

  const dataPoint = useMemo(() => selectedDataPoint, [selectedDataPoint]);

  const sortedAvaliableDataPoints = useMemo(
    () =>
      template.avaliableDataPoints.sort((a, b) =>
        String(a.dataPoint.name).localeCompare(String(b.dataPoint.name))
      ),
    [template.avaliableDataPoints]
  );

  const isVisibleDataPoint = useMemo(
    () =>
      template.visibleDataPoints.some(
        (t) => t.dataPointId === selectedDataPoint?.dataPointId
      ),
    [template.visibleDataPoints, selectedDataPoint]
  );

  return (
    <div className="md:w-5/6 mx-auto my-8 grid grid-cols-7">
      <div className="col-span-3">
        <p className="ml-1 mb-2 text-md">All data points</p>
        <div className="h-96 p-0.5 space-y-0.5 overflow-y-scroll bg-slate-100 dark:bg-slate-700 border-2 border-primary rounded ">
          {sortedAvaliableDataPoints.map((dp, i) => (
            <DataPointListItem
              key={`avaliable-datapoint-${i}`}
              dataPoint={dp}
              selected={selectedDataPoint?.dataPointId === dp.dataPointId}
              onClick={setSelectedDataPoint}
              viewConfig={viewConfig}
            />
          ))}
        </div>
      </div>
      <div className="mt-6 flex flex-col items-center justify-center space-y-2">
        <Button
          disabled={!dataPoint || !isVisibleDataPoint}
          onClick={() => dispatchAction({ type: "up", dataPoint: dataPoint! })}
          className="w-10"
          size="sm"
        >
          <ChevronUpIcon />
        </Button>
        <Button
          disabled={!dataPoint || isVisibleDataPoint}
          onClick={() => dispatchAction({ type: "add", dataPoint: dataPoint! })}
          className="w-10"
          size="sm"
        >
          <ChevronRightIcon />
        </Button>
        <Button
          disabled={!dataPoint || !isVisibleDataPoint}
          onClick={() =>
            dispatchAction({ type: "remove", dataPoint: dataPoint! })
          }
          className="w-10"
          size="sm"
        >
          <ChevronLeftIcon />
        </Button>
        <Button
          disabled={!dataPoint || !isVisibleDataPoint}
          onClick={() =>
            dispatchAction({ type: "down", dataPoint: dataPoint! })
          }
          className="w-10"
          size="sm"
        >
          <ChevronDownIcon />
        </Button>
      </div>
      <div className="col-span-3">
        <p className="ml-1 mb-2 text-md">Selected data points</p>
        <div className="h-96 p-0.5 space-y-0.5 overflow-y-scroll bg-slate-100 dark:bg-slate-700 border-2 border-primary rounded">
          {template.visibleDataPoints.map((dp, i) => (
            <DataPointListItem
              key={`visable-datapoint-${i}`}
              dataPoint={dp}
              selected={selectedDataPoint?.dataPointId === dp.dataPointId}
              onClick={setSelectedDataPoint}
              viewConfig={viewConfig}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
