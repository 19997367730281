import { useState } from "react";

import { createColumnHelper } from "@tanstack/react-table";
import {
  ArrowUturnDownIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";

import { Card } from "../../../components/base/Card";
import { Table } from "../../../components/base/Table";
import { Button } from "../../../components/base/Button";
import { LoadingWheel } from "../../../components/base/LoadingWheel";
import { ConfirmDelete } from "../../../components/shared/ConfirmDelete";
import { IDataPointOption } from "../../../types/DataPoint/DataPointOption";
import { useGlobalHardwareModelFilter } from "../../../contexts/GlobalHardwareFilterContext";
import { CreateEditDataPointOption } from "../../../components/DataPoint/CreateEditDataPointOption";
import useSuperAdminDataPointOptionMutations from "../../../data/datapoint/superadmin/useSuperAdminDataPointOptionMutation";
import { useDataPointLookups } from "../../../hooks/useDataPointLookups";
import { useSuperAdminHardwareLookups } from "../../../hooks/useSuperAdminHardwareLookups";
import { useClipboardCopy } from "../../../hooks/useClipboardCopy";
import { useSuperAdminDataPointLookups } from "../../../hooks/useSuperAdminDataPointLookups";
const colHelper = createColumnHelper<IDataPointOption>();

export function DataPointOptionsPage() {
  const { CopyToClipboardButton } = useClipboardCopy();
  const { delDataPointOption, updateDataPointOption } =
    useSuperAdminDataPointOptionMutations();
  const { globalHardwareModelFilter } = useGlobalHardwareModelFilter();
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editDataPointOption, setEditDataPointOption] = useState<
    IDataPointOption | undefined
  >(undefined);
  const [deleteDataPointOption, setDeleteDataPointOption] = useState<
    IDataPointOption | undefined
  >(undefined);
  const {
    isLoading: isHardwareLookupLoading,
    manufacturerLookup,
    modelLookup,
    typeLookup,
  } = useSuperAdminHardwareLookups();
  const { isLoading: isDataPointLookupLoading, dataPointLookup } =
    useSuperAdminDataPointLookups(globalHardwareModelFilter?.hardwareTypeId);

  const handleDeleteDataPointOption = (dataPointOption: IDataPointOption) => {
    setDeleteDataPointOption(dataPointOption);
    setDeleteOpen(true);
  };

  const handleConfirmDeleteDataPointOption = () => {
    delDataPointOption.mutate(deleteDataPointOption!);
    setDeleteOpen(false);
  };

  const handleRestoreDataPointOption = (dataPointOption: IDataPointOption) => {
    updateDataPointOption.mutate({ ...dataPointOption, restore: true });
  };

  const handleEditDataPointOption = (dataPointOption: IDataPointOption) => {
    setEditDataPointOption(dataPointOption);
    setEditOpen(true);
  };

  const handleCreateEditClose = () => {
    editDataPointOption &&
      setTimeout(() => setEditDataPointOption(undefined), 200);
    setEditOpen(false);
  };

  const cols = [
    colHelper.accessor((row) => typeLookup(row.hardwareTypeId)?.name, {
      header: "Type",
    }),
    colHelper.accessor(
      (row) => manufacturerLookup(row.hardwareManufacturerId)?.name,
      { header: "Manufacturer" }
    ),
    colHelper.accessor((row) => modelLookup(row.hardwareModelId)?.name, {
      header: "Model",
    }),
    colHelper.accessor((row) => dataPointLookup(row.dataPointId)?.name, {
      header: "Data Point",
    }),
    colHelper.accessor("value", { header: "Value" }),
    colHelper.accessor("label", { header: "Label" }),
    colHelper.display({
      header: "Actions",
      cell: (info) => (
        <div className="flex items-center space-x-4">
          {info.cell.row.original.deletedOn ? (
            <ArrowUturnDownIcon
              className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
              onClick={() => handleRestoreDataPointOption(info.row.original)}
            />
          ) : (
            <>
              <CopyToClipboardButton iconOnly copyText={info.row.original.id} />
              <PencilIcon
                className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
                onClick={() => handleEditDataPointOption(info.row.original)}
              />
              <TrashIcon
                className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
                onClick={() => handleDeleteDataPointOption(info.row.original)}
              />
            </>
          )}
        </div>
      ),
    }),
  ];

  return (
    <>
      <CreateEditDataPointOption
        open={editOpen}
        onClose={handleCreateEditClose}
        dataPointOption={editDataPointOption}
      />
      <ConfirmDelete
        itemName={"Data Point Option"}
        open={deleteOpen}
        onCancel={() => setDeleteOpen(false)}
        onConfirm={() => handleConfirmDeleteDataPointOption()}
      />
      <Card title="Data Point Options">
        <div className="my-6">
          <Button onClick={() => setEditOpen(true)}>
            Add Data Point Option
          </Button>
        </div>
        {isHardwareLookupLoading || isDataPointLookupLoading ? (
          <LoadingWheel />
        ) : (
          <div>
            <Table<IDataPointOption>
              url="/superadmin/datapoint/option"
              queryKey={["superadmin", "datapoint", "options"]}
              columns={cols}
              predefinedFilterFields={
                globalHardwareModelFilter
                  ? [
                      {
                        field: "hardwareModelId",
                        value: globalHardwareModelFilter.id,
                        fieldLabel: "Hardware Model",
                        valueLabel: globalHardwareModelFilter.name,
                      },
                    ]
                  : undefined
              }
              enableShowDeleted
            />
          </div>
        )}
      </Card>
    </>
  );
}
