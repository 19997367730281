import { Dispatch, SetStateAction, useState } from "react";
import { useUserContext } from "../../../contexts/UserContext";
import { ViewTemplateSelect } from "./ViewTemplateSelect";
import { IHardware } from "../../../types/Hardware/Hardware";
import { ITemplateConfiguration } from "../../../types/Template/Template";
import { Button } from "../../base/Button";
import { EditViewConfigurationModal } from "./EditViewConfigurationModal";
import { TemplateEditContextProvider } from "../../../contexts/TemplateEditContext";
import { Toggle } from "../../base/Toggle";

interface ViewSettingsProps {
  selectedHardware: IHardware;
  tableView: boolean;
  setTableView: Dispatch<SetStateAction<boolean>>;
  selectedTemplate?: ITemplateConfiguration;
  setSelectedTemplate: Dispatch<
    SetStateAction<ITemplateConfiguration | undefined>
  >;
  superAdminView: boolean;
  setSuperAdminView: Dispatch<SetStateAction<boolean>>;
}

export function ViewSettings({
  selectedHardware,
  tableView,
  setTableView,
  selectedTemplate,
  setSelectedTemplate,
  superAdminView,
  setSuperAdminView,
}: ViewSettingsProps) {
  const { installerId, isInstaller, isSuperAdmin } = useUserContext();
  const [editViewOpen, setEditViewOpen] = useState(false);

  return (
    <>
      <TemplateEditContextProvider>
        <EditViewConfigurationModal
          open={editViewOpen}
          onClose={() => setEditViewOpen(false)}
          selectedHardware={selectedHardware}
        />
      </TemplateEditContextProvider>
      <div>
        <div className="space-y-4 md:space-y-0 md:flex md:flex-row md:justify-between">
          {installerId || isInstaller ? (
            <div className="w-full md:w-64 border-primary border rounded-lg flex flex-row">
              <div
                onClick={() => setTableView(false)}
                className={`${
                  !tableView
                    ? "bg-primary cursor-default"
                    : "hover:bg-primary/60 hover:cursor-pointer"
                } py-2 px-3 w-1/2 text-center rounded-s-md`}
              >
                Card View
              </div>
              <div
                onClick={() => setTableView(true)}
                className={`${
                  tableView
                    ? "bg-primary cursor-default"
                    : "hover:bg-primary/60 hover:cursor-pointer"
                } py-2 px-3 w-1/2 text-center rounded-e-md`}
              >
                Table View
              </div>
            </div>
          ) : (
            <div />
          )}
          {isSuperAdmin && (
            <div className="flex flex-1 items-center md:px-4">
              <Toggle
                label="Super Admin"
                checked={superAdminView}
                onChange={setSuperAdminView}
              />
            </div>
          )}
          <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:items-center md:space-x-4">
            {(installerId || isInstaller) && (
              <ViewTemplateSelect
                selectedHardware={selectedHardware}
                selectedTemplate={selectedTemplate}
                setSelectedTemplate={setSelectedTemplate}
              />
            )}
            {!installerId && (
              <Button className="h-full" onClick={() => setEditViewOpen(true)}>
                Edit View
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
