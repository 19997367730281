import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";

import clsx from "clsx";
import { DateTime } from "luxon";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName, IconPrefix } from "@fortawesome/free-solid-svg-icons";

import { LiveGraph } from "./LiveGraph";
import { ValueAction } from "./ValueAction";
import { ToggleAction } from "./ToggleAction";
import { SelectAction } from "./SelectAction";
import { IUser } from "../../../types/User/User";
import { Card } from "../../../components/base/Card";
import useApiHelper from "../../../hooks/useApiHelper";
import { addTimeout } from "../../../utils/addTimeout";
import useDevice from "../../../data/device/useDevice";
import { IHardware } from "../../../types/Hardware/Hardware";
import { useUserContext } from "../../../contexts/UserContext";
import { IDataPoint } from "../../../types/DataPoint/DataPoint";
import { useLiveReading } from "../../../contexts/LiveReadingContext";
import useOrganisation from "../../../data/organisation/useOrganisation";
import { IActionProfile, InputType } from "../../../types/Action/ActionProfile";
import {
  PendingAction,
  usePendingActions,
} from "../../../contexts/PendingActionContext";
import useOrganisationUsers from "../../../data/organisationUsers/useOrganisationUsers";
import {
  IProcessedReading,
  ProcessedReading,
  useReadingsProcessor,
} from "../../../hooks/useReadingProcessor";
import { PendingActionState } from "../../../types/Action/Action";

interface ReadingCardProps {
  hardware: IHardware;
  dataPoint: IDataPoint;
  timestamp?: string;
}

export function ReadingCard({
  hardware,
  dataPoint,
  timestamp,
  ...rest
}: ReadingCardProps) {
  const { post } = useApiHelper();
  const { hasPendingAction, addPendingAction, removePendingAction } =
    usePendingActions();
  const { device } = useDevice(hardware.deviceId ?? "");
  const { processorReady, readingProcessor } = useReadingsProcessor();
  const { latest } = useLiveReading();
  const { organisation } = useOrganisation();
  const { users } = useOrganisationUsers();
  const { userId } = useUserContext();
  const [organisationUser, setOrganisationUser] = useState<IUser>();
  const [processedReading, setProcessedReading] = useState<ProcessedReading>();
  const [pendingAction, setPendingAction] =
    useState<PendingActionState>("idle");
  const actionTimedOutTimeOut = useRef<NodeJS.Timeout>();

  const handleApplyAction = (val: string, actionId: string) => {
    const valueParts = val.split(".");
    // Check to see if we have more than one item after split (we have a . in the string)
    // Then check to see if the string after the . is not 0
    const isFloat =
      valueParts.length > 1 ? (valueParts[1] !== "0" ? true : false) : false;
    const value = isFloat ? parseFloat(val) : parseInt(val);

    setPendingAction("pending");
    addPendingAction({
      dataPointId: dataPoint.id,
      expectedValue: value,
      timeout: addTimeout(
        dataPoint.actions[0].responseTimeout === 0
          ? 60
          : dataPoint.actions[0].responseTimeout!
      ),
    });

    post(`/v1/action/${hardware.id}`, {
      value,
      action: actionId,
    }).then(() => {
      //TODO: add notification here
    });
  };

  const getAction = (action: IActionProfile) => {
    let conencted =
      device.data && device.data.length > 0
        ? device.data![0].device.cloud.connected
        : false;

    const pr = processedReading as IProcessedReading;

    const props = {
      onApply: handleApplyAction,
      actionId: action.id,
      pendingAction: pendingAction,
      currentValue: pr.hasValue ? pr.reading.transformedValue! : 0,
      disabled:
        !conencted || organisation.data?.readRole === organisationUser?.roleId,
      paidTier: hardware.subscriptionId !== null,
    };

    switch (action.inputType) {
      case InputType.None:
        return <></>;
      case InputType.Select:
        return <SelectAction {...props} options={pr.dataPoint.options!} />;
      case InputType.Toggle:
        let toggleOpts = pr.dataPoint.options.filter((opt) => !opt.readOnly);

        if (toggleOpts?.length! < 2) {
          return <></>;
        } else {
          return (
            <ToggleAction
              {...props}
              leftLabel={toggleOpts![0].label}
              rightLabel={toggleOpts![1].label}
            />
          );
        }
      case InputType.Value:
      default:
        return (
          <ValueAction
            {...props}
            step={action.step}
            min={pr.dataPoint.profiles[0].minimumValue}
            max={pr.dataPoint.profiles[0].maximumValue}
            precision={pr.dataPoint.profiles[0].precision}
            unit={pr.dataPoint.unit}
          />
        );
    }
  };

  useEffect(() => {
    if (!organisationUser && !organisation.isLoading && !users.isLoading) {
      setOrganisationUser(users.data?.find((u) => u.id === userId));
    }
  }, [
    organisation.isLoading,
    users.isLoading,
    users.data,
    userId,
    organisationUser,
  ]);

  useEffect(() => {
    //Find reading for DataPoint
    const reading = latest?.readingData.find(
      (r) => r.dataPointId === dataPoint.id
    );

    if (reading) {
      // Throw reading & dataPoint for this card into processor
      let updatedReading = readingProcessor(reading!, dataPoint);

      if (updatedReading.hasValue) {
        let pendingAction = hasPendingAction(dataPoint.id);

        // If a pending action is stored
        if (pendingAction) {
          if (pendingAction === "timedOut") {
            setPendingAction("timedOut");
            actionTimedOutTimeOut.current = setTimeout(
              () => setPendingAction("idle"),
              5000
            );
          } else {
            let pa = pendingAction as PendingAction;

            if (updatedReading?.reading.transformedValue === pa.expectedValue) {
              // Update worked, clear flag
              setPendingAction("idle");
              removePendingAction(dataPoint.id);
            }
          }
        }
      } else {
        console.warn(`No value for datapoint ${dataPoint.name} `);
      }

      setProcessedReading(updatedReading);
    } else {
      //Fake a reading
      let updatedReading = readingProcessor(null, dataPoint);
      setProcessedReading(updatedReading);
    }

    return () => {
      if (actionTimedOutTimeOut.current) {
        clearTimeout(actionTimedOutTimeOut.current);
      }
    };
  }, [latest, readingProcessor]);

  if (!processorReady || organisation.isLoading || users.isLoading) {
    return <></>;
  } else {
    if (!processedReading) {
      return <></>;
    } else {
      const displayIcon = processedReading.dataPoint.icon
        ? (processedReading.dataPoint.icon.split(",") as [IconPrefix, IconName])
        : (["fas", "question"] as [IconPrefix, IconName]);

      const getHeight = () => {
        if (
          processedReading.dataPoint.actions.length > 0 &&
          processedReading.dataPoint.graphEnable
        ) {
          return 352;
        } else if (processedReading.dataPoint.actions.length > 0) {
          return 176;
        } else if (processedReading.dataPoint.graphEnable) {
          return 264;
        } else {
          return 88;
        }
      };

      return (
        <Card {...rest} className={`h-[${getHeight()}]`}>
          <div className="p-1 grid grid-cols-6 gap-2">
            <div className="flex items-center justify-center">
              <FontAwesomeIcon
                icon={displayIcon}
                className="h-auto max-h-20 aspect-square text-gray-500 dark:text-gray-300"
              />
            </div>
            <div className="col-span-5">
              <h4 className="text-2xl text-right">
                {processedReading.dataPoint.name}
              </h4>
              <h2
                className={clsx(
                  "text-5xl text-right",
                  !processedReading.hasValue &&
                    "text-gray-400 dark:text-gray-600"
                )}
              >
                {processedReading.displayString}
              </h2>
            </div>
          </div>
          {Boolean(
            processedReading.dataPoint.graphEnable && hardware !== undefined
          ) && (
            <div className="mt-4">
              <LiveGraph
                hardwareId={hardware.id}
                dataPoint={processedReading.dataPoint}
                endDate={latest ? latest.timestamp : DateTime.now().toISO()!}
              />
            </div>
          )}
          {processedReading.dataPoint.actions.length > 0 && (
            <div className="mt-4">
              {getAction(processedReading.dataPoint.actions[0])}
            </div>
          )}
        </Card>
      );
    }
  }
}
