import {
  ReactElement,
  Dispatch,
  createContext,
  useContext,
  useReducer,
} from "react";

export interface PendingAction {
  dataPointId: string;
  expectedValue: number;
  timeout: Date;
}

interface IPendingActionContext {
  hardwareId?: string;
  pendingActions: PendingAction[];
}

interface PendingActionContextValue {
  hardwareId?: string;
  pendingActions: PendingAction[];
  dispatch: Dispatch<PendingActionReducerActions>;
}

const initialState = {
  hardwareId: undefined,
  pendingActions: [],
};

type PendingActionReducerActions =
  | {
      type: "addPendingAction";
      payload: PendingAction;
    }
  | {
      type: "setHardware";
      payload: string;
    }
  | {
      type: "removePendingAction";
      payload: string;
    }
  | {
      type: "clear";
    };

function pendingActionReducer(
  state: IPendingActionContext,
  action: PendingActionReducerActions
): IPendingActionContext {
  switch (action.type) {
    case "setHardware":
      state.hardwareId = action.payload;
      return state;
    case "addPendingAction":
      state.pendingActions = [...state.pendingActions, action.payload];
      return state;
    case "removePendingAction":
      let newActions = state.pendingActions.filter(
        (pa) => pa.dataPointId !== action.payload
      );
      return { ...state, pendingActions: newActions };
    case "clear":
      return { ...initialState };
    default:
      return state;
  }
}

const PendingActionContext = createContext<PendingActionContextValue>({
  ...initialState,
  dispatch: () => {},
});

export function PendingActionContextProvider({
  children,
}: {
  children: ReactElement | ReactElement[];
}) {
  const [state, setState] = useReducer(pendingActionReducer, initialState);

  return (
    <PendingActionContext.Provider value={{ ...state, dispatch: setState }}>
      {children}
    </PendingActionContext.Provider>
  );
}

export function usePendingActions() {
  const pendingActionsCtx = useContext(PendingActionContext);

  if (!pendingActionsCtx) {
    throw Error(
      "usePendingActions() must be used within a PendingActionContextProvider"
    );
  }

  const setHardware = (hardwareId: string) =>
    pendingActionsCtx.dispatch({ type: "setHardware", payload: hardwareId });

  const addPendingAction = (action: PendingAction) =>
    pendingActionsCtx.dispatch({ type: "addPendingAction", payload: action });

  const removePendingAction = (dataPointId: string) =>
    pendingActionsCtx.dispatch({
      type: "removePendingAction",
      payload: dataPointId,
    });

  const hasPendingAction = (
    dataPointId: string
  ): PendingAction | string | undefined => {
    let pendingAction = pendingActionsCtx.pendingActions.find(
      (pa) => pa.dataPointId === dataPointId
    );

    if (pendingAction) {
      // Check timeout hasn't been reached
      if (pendingAction.timeout < new Date()) {
        console.log(
          `Pending action for ${pendingAction.dataPointId} timed out`,
          [pendingAction.timeout, new Date()]
        );
        pendingActionsCtx.dispatch({
          type: "removePendingAction",
          payload: dataPointId,
        });

        return "timedOut";
      }
    }

    return pendingAction;
  };

  const reset = () => pendingActionsCtx.dispatch({ type: "clear" });

  return {
    hardwareId: pendingActionsCtx.hardwareId,
    setHardware,
    addPendingAction,
    removePendingAction,
    hasPendingAction,
    reset,
  };
}
