import { useEffect, useState } from "react";

import { Button } from "../../base/Button";
import { LoadingWheel } from "../../base/LoadingWheel";
import { IHardware } from "../../../types/Hardware/Hardware";
import { useUserContext } from "../../../contexts/UserContext";
import { Modal, ModalControlProps } from "../../../layouts/Modal";
import { TemplateViewConfigDatapointSelect } from "../../shared/TemplateViewConfigDataPointSelect";
import { ISingleTile, TileVisability } from "../../../types/Tile/Tile";
import { useTemplateEditContext } from "../../../contexts/TemplateEditContext";
import useTileViewConfigurations from "../../../data/configuration/useTileViewConfigurations";
import useDataPointsByHardwareModel from "../../../data/datapoint/useDataPointsByHardwareModel";
import useTileViewConfigurationsMutations from "../../../data/configuration/useTileViewConfigurationsMutations";

interface EditViewConfigurationModalProps extends ModalControlProps {
  selectedHardware: IHardware;
}

export function EditViewConfigurationModal({
  open,
  onClose,
  selectedHardware,
}: EditViewConfigurationModalProps) {
  const { isInstaller } = useUserContext();
  const { state: templateState, dispatch } = useTemplateEditContext();
  const { createConfiguration, updateConfiguration } =
    useTileViewConfigurationsMutations();
  const { tileViewConfigurations } = useTileViewConfigurations(
    selectedHardware.id
  );
  const { dataPoints } = useDataPointsByHardwareModel(
    selectedHardware.hardwareModelId
  );
  const [submitting, setSubmitting] = useState(false);

  const handleClose = () => {
    onClose(true);
  };

  const handleSubmit = () => {
    setSubmitting(true);

    let tiles: ISingleTile[] = [];

    templateState.avaliableDataPoints.forEach((dp) => {
      tiles.push({
        dataPointId: dp.dataPointId,
        visability: dp.hide ? TileVisability.exclude : TileVisability.hide,
        control: dp.control,
      });
    });

    templateState.visibleDataPoints.forEach((dp) => {
      tiles.push({
        dataPointId: dp.dataPointId,
        visability: TileVisability.show,
        control: dp.control,
      });
    });

    if (
      tileViewConfigurations.data![0].id ===
      "00000000-0000-0000-0000-000000000000"
    ) {
      createConfiguration.mutate(
        {
          ...tileViewConfigurations.data![0],
          view: {
            ...tileViewConfigurations.data![0].view,
            tiles: tiles,
          },
        },
        {
          onSuccess: () => {
            handleClose();
          },
          onSettled: () => {
            setSubmitting(false);
          },
        }
      );
    } else {
      updateConfiguration.mutate(
        {
          ...tileViewConfigurations.data![0],
          view: {
            ...tileViewConfigurations.data![0].view,
            tiles: tiles,
          },
        },
        {
          onSuccess: () => {
            handleClose();
          },
          onSettled: () => {
            setSubmitting(false);
          },
        }
      );
    }
  };

  useEffect(() => {
    if (
      !dataPoints.isLoading &&
      dataPoints.data &&
      !tileViewConfigurations.isLoading &&
      tileViewConfigurations.data
    ) {
      dispatch({
        type: "loadFromViewConfig",
        viewConfig: tileViewConfigurations.data[0],
        dataPoints: dataPoints.data!,
      });
    }

    return () => {
      dispatch({ type: "reset" });
    };
  }, [
    dataPoints.isLoading,
    dataPoints.data,
    tileViewConfigurations.isLoading,
    tileViewConfigurations.data,
    dispatch,
  ]);

  return (
    <Modal dialogStatic open={open} onClose={onClose} title="Edit View">
      {dataPoints.isLoading ||
      tileViewConfigurations.isLoading ||
      submitting ? (
        <div className="flex py-10 items-center justify-center">
          <LoadingWheel />
        </div>
      ) : (
        <>
          <p>
            Any changes you make here will apply only to your user account and
            only on this device. These customizations won't carry over to other
            devices or affect other users.
          </p>
          {isInstaller && (
            <>
              <br />
              <p>
                If you're looking to make changes that apply universally across
                devices, users, or even customers, consider using the Template
                feature. This option is designed for creating standardized views
                and settings that can be shared and applied across multiple
                users. You can find the Template feature in the Settings section
                of the application.
              </p>
            </>
          )}
          <TemplateViewConfigDatapointSelect viewConfig />
          <div className="flex items-center space-x-4">
            <Button onClick={handleSubmit}>Save</Button>
            <Button variant="outlined" onClick={handleClose}>
              Close
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
}
